/** @format */

import React, { useState, useEffect, useRef } from 'react';
import './AiCard.css';
import {
    contentArticleDataIsEmpty,
    getArticleCompleted,
    getArticleFromOutline,
    getCompletedHeadings,
    getContentArticle,
    getCurrentArticleId,
    getHideAiCard,
    getOutlinePrompt,
    getTotalHeadings,
    resetAndGetOutline,
    setArticleAltered,
    setOutlinePrompt,
    stopGeneratingArticle,
    requestArticleById,
    setTotalHeadings,
    setArticleCompleted,
    setCompletedHeadings,
    setOutlineProgressStatus
} from '../../../reducers/articles';
// import { getUserData } from '../../../reducers/user';
import { useDispatch, useSelector } from 'react-redux';
import { getGlobalState } from '../../../globals';
import {getEditorHTMLData, getParsedData} from '../../../reducers/textEditor';
import { useParams } from 'react-router-dom';
import AppModal from '../../../Shared/AppModal';
import { CircleCrossIcon } from '../../../UI_utils/SVGIcons';
import TryAgainModal from '../../../Shared/TryAgainModal';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Select from 'react-select';
import CustomSelect from "../../../Shared/CustomSelect";
import CustomCheckbox from "../../../Shared/CustomCheckbox";
import CustomToggle from "../../../Shared/CustomToggle";
import AppSwitcher from "../../../Shared/AppSwitcher";
import AiCardPrompt from "./AiCardPrompt";
import AiCardModal from "./AiCardModal";
import AiCardOptions from "./AiCardOptions";

const AiCard = ({
    isOutline,
    switchToArticle,
    generating,
    firstLoad,
    outlineProgressStatus,
    aiMode,
    setAiMode,
    selectedTestPrompt,
    setSelectedTestPrompt,
    toggleShowUncompiledArticle,
    showUncompiledArticle
}) => {
    const siteId = getGlobalState('site_id');
    const dispatch = useDispatch();
    const editorHTMLData = useSelector(getEditorHTMLData);
    const parsedData = useSelector(getParsedData);
    const outlinePrompt = useSelector(getOutlinePrompt);
    const hideAiCard = useSelector(getHideAiCard);

    const [showPrompt, setShowPrompt] = useState(false);
    const [promptType, setPromptType] = useState('article')
    const [showCardOnArticle, setShowCardOnArticle] = useState(false);
    const [showFreshArticleText, setShowFreshArticleText] = useState(false);

    const currentArticleId = useSelector(getCurrentArticleId);
    const hasSharedToken = getGlobalState('sharedToken');

    // const userData = useSelector(getUserData);
    const { id } = useParams();

    /**
     * logic for generating article
     */

    const completedHeadings = useSelector(getCompletedHeadings);
    const totalHeadings = useSelector(getTotalHeadings);
    const articleCompleted = useSelector(getArticleCompleted);
    const [completedHeadingsUpdated, setCompletedHeadingsUpdated] = useState(false);

    useEffect(() => setShowCardOnArticle(articleCompleted), [articleCompleted])

    const [selectedMarketSource, setSelectedMarketSource] = useState(false);
    /**
     * Text that will be displayed
     */
    const returnGeneratingArticle = () =>
        `The article is being generated by Al following the brief, the outline and the user prompt.`;
    const ARTICLE_ALMOST_DONE = 'Almost ready! Fine-tuning the content to ensure perfect alignment with the context. This can take up to one or two minutes.';
    const FRESH_ARTICLE_TEXT = 'Completed! The final version of the article has been generated below. Feel free to refine it further and export.';
    const ARTICLE_TEXT = 'Article draft complete. Review and refine content, then proceed with your website publication process.';
    const GENERATING_OUTLINE_TEXT = 'AI is generating the outline based on top-searched keywords and ranking signals from SERP-leading articles.';
    const GENERATING_ARTICLE_TEXT = 'The article is being generated. This may take up to 10 minutes depending on the length of the article.';
    const OUTLINE_TEXT = 'AI-generated outline for your topic based on the brief and the structure of the top ranking articles. Edit Title, Headings, and keywords as needed, then generate the article.';
    const OUTLINE_PROMPT_TEXT = 'Optional: Add custom guidelines for the AI outline generation.';
    const ARTICLE_PROMPT_TEXT = 'Optional: Add custom guidelines for AI content generation.'
    const CHECKING_STATUS_TEXT = 'Researching keyword as a topic.';
    const STOP_ARTICLE_GENERATION = 'Article generation stopped. You can now edit the content and export it, or return to the Outline view to adjust settings and regenerate.';

    // State variables to track whether each text has been shown at least once
    const [outlineTextShown, setOutlineTextShown] = useState('');
    const [outlinePromptTextShown, setOutlinePromptTextShown] = useState('');
    const [articleTextShown, setArticleTextShown] = useState('');
    const [freshArticleTextShown, setFreshArticleTextShown] = useState('');
    const [generatingArticleTextShown, setGeneratingArticleTextShown] = useState('');
    const [topicStatusTextShown, setTopicStatusTextShown] = useState('');
    const [generatingOutlineTextShown, setGeneratingOutlineTextShown] = useState('');
    const [articleAlmostDoneText, setArticleAlmostDoneText] = useState('');

    const showText = () => {
        if (outlineProgressStatus === null) {
            return { text: CHECKING_STATUS_TEXT, shownText: topicStatusTextShown, setShownText: setTopicStatusTextShown };
        }

        if (articleCompleted === false) {
            // if (completedHeadings == totalHeadings) {
            //     return { text: ARTICLE_ALMOST_DONE, shownText: articleAlmostDoneText, setShownText: setArticleAlmostDoneText };
            // }
            return { text: GENERATING_ARTICLE_TEXT, shownText: generatingArticleTextShown, setShownText: setGeneratingArticleTextShown };
        }

        if (!isOutline) {
            if (showCardOnArticle && !disableStopGenerating) {
                if(showFreshArticleText) {
                    return {text: FRESH_ARTICLE_TEXT, shownText: freshArticleTextShown, setShownText: setFreshArticleTextShown};
                }
                return {text: ARTICLE_TEXT, shownText: articleTextShown, setShownText: setArticleTextShown};
            }
            if (showCardOnArticle && disableStopGenerating)
                return { text: STOP_ARTICLE_GENERATION, shownText: articleTextShown, setShownText: setArticleTextShown };

            return {};
        }

        if (generating) {
            return { text: GENERATING_OUTLINE_TEXT, shownText: generatingOutlineTextShown, setShownText: setGeneratingOutlineTextShown };
        }

        if (showPrompt) {
            return { text: promptType === 'outline' ?  OUTLINE_PROMPT_TEXT : ARTICLE_PROMPT_TEXT, shownText: outlinePromptTextShown, setShownText: setOutlinePromptTextShown };
        }

        return { text: OUTLINE_TEXT, shownText: outlineTextShown, setShownText: setOutlineTextShown };
    };


    useEffect(() => {
        if (articleCompleted !== false) return setCompletedHeadingsUpdated(false);

        setShowCardOnArticle(true);
        setShowFreshArticleText(true);

        // if last character is '.' it means that is completed.
        // I didn't want to compare the length because it can be different if
        // initially the completed headings had one digit and later it had 2 digits
        const textCompleted = generatingArticleTextShown.slice(-1) === '.';

        if (textCompleted) {
            setCompletedHeadingsUpdated(true);
        }
    }, [completedHeadings]);

    /**
     * Text that needs to be displayed it will be "typed"
     */
    const TIMEOUT = 150;

    const [text, setText] = useState(outlinePrompt);

    useEffect(() => setText(outlinePrompt), [outlinePrompt]);

    useEffect(() => {
        const showTextObject = showText();
        const texts = showTextObject?.text;

        if (texts !== undefined) {
            const textCompleted = showTextObject?.shownText.slice(-1) === '.';
            if (textCompleted) {
                return showTextObject.setShownText(showTextObject?.text);
            }

            // Split the full text into words
            const words = texts.split(' '); // Split the text by spaces to get individual words

            const intervalId = setInterval(() => {
                showTextObject.setShownText((prevText) => {
                    // Split the current shown text by words to keep track
                    const currentWords = prevText.split(' ');
                    const currentWordCount = currentWords.length;

                    // If there are more words to show, append the next word
                    if (currentWordCount < words.length) {
                        return words.slice(0, currentWordCount + 1).join(' ');
                    } else {
                        // If all words are shown, clear the interval
                        clearInterval(intervalId);
                        return prevText;
                    }
                });
            }, TIMEOUT);

            return () => {
                // console.log('Cleanup');
                clearInterval(intervalId);
            };
        }
    }, [isOutline, showPrompt, generating, outlineProgressStatus, completedHeadings, articleCompleted, totalHeadings, showFreshArticleText]);

    const dynamicMainClass = () => {
        let classList = '';
        if (isOutline) classList += ` ai-card--outline ${generating ? 'ai-card--generating-outline' : ''}`;
        // articleCompleted
        if(!isOutline) classList += ` ai-card--article ${generating || articleCompleted !== false ? 'ai-card--generating-article' : ''}`;

        if(articleCompleted === false) classList += ` ai-card--full-text`;

        return classList
    };

    const resetOutline = () => {
        if (generating) return false;

        dispatch(resetAndGetOutline(currentArticleId, selectedTestPrompt.value, text, false));
    };

    const showPromptInput = (type) => {
       setPromptType(type)
       return generating ? false : setShowPrompt(true);
    } 
    const hidePromptInput = () => setShowPrompt(false);

    //Confirm generate article
    const articleIsEmpty = useSelector(contentArticleDataIsEmpty);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    //End confirm generate article

    const generateArticle = () => {
        if (!articleIsEmpty && !showConfirmModal) {
            return setShowConfirmModal(true);
        }

        setShowConfirmModal(false);

        if (promptType === 'article') {
            dispatch(setCompletedHeadings(0))
            dispatch(setArticleCompleted(false))

            // dispatch(setTotalHeadings(0))
            setTimeout(() => {
                dispatch(
                    getArticleFromOutline({ id: id, outline: editorHTMLData, prompt: text, mode: aiMode.value, test_prompts: selectedTestPrompt.value, get_from_uk: selectedMarketSource })
                );
            switchToArticle();
             hidePromptInput();

            }, 300)
        } else {
            dispatch(setOutlineProgressStatus(null))
            setShowPrompt(false)
            resetOutline()
        }
       
    };

    /**
     * Logic that shrinks or enlarges the text area based on the content
     */
    const textareaRef = useRef(null);

    useEffect(() => {
        const textarea = textareaRef.current;

        if (textarea) {
            updateTextareaHeight(textarea);
        }
    }, [showPrompt]);

    const handleTextareaChange = (event) => {
        setText(event.target.value);
        updateTextareaHeight(event.target);
    };

    const updateTextareaHeight = (element) => {
        element.style.height = '0';
        element.style.height = `${element.scrollHeight}px`;
    };

    const [disableStopGenerating, setDisableStopGenerating] = useState(false);

    const stopGenerating = () => {
        if (disableStopGenerating) return;

        setDisableStopGenerating(true);

        dispatch(stopGeneratingArticle(currentArticleId));
    };
    useEffect(() => {
        setDisableStopGenerating(false);
        setShowFreshArticleText(false);
    }, [isOutline]);

    const showUncompiledArticleTooltipText = 'During content generation, the<br>Writer is generating content for<br>each heading, using its built-in<br>processing capabilities to<br>produce a highly effective<br>output.<br><br>If the final compiled version is<br>not good enough, you can get<br>more content from the<br>uncompiled content.'
    const setShowUncompiledArticle = (value) => toggleShowUncompiledArticle(value);

    const disableGeneratingArticle = () => {
        return promptType === 'article' &&
            !parsedData.headings1.trim() &&
            !parsedData.paragraphs.trim() &&
            !parsedData.headings.trim()
    }

    //if is article and we dont need to show card, return empty component
    if ((!showCardOnArticle && !generating && !isOutline && articleCompleted !== false) || (hideAiCard && isOutline) || hasSharedToken) return <></>;

    return (
        <div className="ai-card__wrapper">
            {showConfirmModal && (
                <AiCardModal
                    promptType={promptType}
                    generateArticle={generateArticle}
                    cancelArticle={() => setShowConfirmModal(false)}
                />
            )}

            <div className={`ai-card ${dynamicMainClass()}`}>
                <div className="ai-card__content">
                    <div className={`ai-card-text ${showPrompt ? 'pt' : ''}`}>
                        {showText()?.shownText}
                        <span className="blinking-dot"></span>
                    </div>
                    <AiCardOptions
                        isOutline={isOutline}
                        outlineProgressStatus={outlineProgressStatus}
                        showPrompt={showPrompt}
                        hidePromptInput={hidePromptInput}
                        generateArticle={generateArticle}
                        promptType={promptType}
                        articleCompleted={articleCompleted}
                        completedHeadings={completedHeadings}
                        totalHeadings={totalHeadings}
                        disableStopGenerating={disableStopGenerating}
                        stopGenerating={stopGenerating}
                        setShowUncompiledArticle={setShowUncompiledArticle}
                        showUncompiledArticle={showUncompiledArticle}
                        showUncompiledArticleTooltipText={showUncompiledArticleTooltipText}
                        showPromptInput={(input) => showPromptInput(input)}
                        disableGeneratingArticle={disableGeneratingArticle()}
                    />
                </div>
                {(showPrompt && isOutline && (
                    <AiCardPrompt
                        promptType={promptType}
                        text={text}
                        handleTextareaChange={handleTextareaChange}
                        textareaRef={textareaRef}
                        aiMode={aiMode}
                        setAiMode={setAiMode}
                        selectedTestPrompt={selectedTestPrompt}
                        setSelectedTestPrompt={setSelectedTestPrompt}
                        selectedMarketSource={selectedMarketSource}
                        setSelectedMarketSource={setSelectedMarketSource}
                        isOutline={isOutline}
                    />
                ))}
            </div>
        </div>
    );
};

export default AiCard;
