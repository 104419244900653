import React, {useEffect, useState} from 'react';
import './ArticlesSidebar.css'

import AppOverlay from "../../Shared/AppOverlay";
import ArticlesSidebarHeader from "./ArticlesSidebarHeader";
import ArticlesSidebarItem from "./ArticlesSidebarItem";
import {customSortedTable, extractHeadingContent} from "../dashboardComponentHelpers";
import {dashboardData, dashboardTotalRows, getDashboardData, setTotalRows} from "../../reducers/dashboard";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import {shortFormatDate} from "../../utils/dateUtility";
import ArticlesSidebarItemPlaceholder from "./ArticlesSidebarItemPlaceholder";
import {getGlobalState, removeQueryParam} from "../../globals";
import {setShowArticleSidebar} from "../../reducers/applicationMode";
import {setShowNewArticleModal} from "../../reducers/articles";

const ArticlesSidebar = ({open, close, absoluteSidebar = true, catchHasArticles}) => {
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const urlSearch = queryParams.get("search") ?? '';
    const siteId = getGlobalState('site_id');
    const defaultFilters = siteId ? { project: [siteId] } : null;

    const dispatch = useDispatch();
    const dashboard = useSelector(dashboardData);
    const totalRows = useSelector(dashboardTotalRows);



    const [currentPage, setCurrentPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(1000);
    const [searchString, setSearchString] = useState(urlSearch);
    const [totalRowsDisplay, setTotalRowsDisplay] = useState(totalRows);
    const [loading, setLoading] = useState(true);


    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        if(defaultFilters) {
            dispatch(
                getDashboardData(
                    currentPage,
                    rowsPerPage,
                    searchString,
                    defaultFilters,
                )
            );
        }
    }, []);

    useEffect(() => {
        const sorted = dashboard && (dashboard.some((article) => article.empty) ? [] : customSortedTable(dashboard));
        setTableData(sorted);

        //it gets complicated and maybe is best to move it on reducers
        if(sorted) {
            if(typeof catchHasArticles === 'function') catchHasArticles(!!searchString || !!sorted.length)
        }
    }, [dashboard]);


    useEffect(() => {
        setTotalRowsDisplay(totalRows);
        if (!totalRows) {
            setLoading(true);
        } else {
            setLoading(false);
        }
        if (totalRows === 0) {
            setLoading(false);
        }
    }, [totalRows]);

    const searchHandler = (term) => {
        setSearchString(term);
        setTableData([]);
        setLoading(true)
        dispatch(
            getDashboardData(
                currentPage,
                rowsPerPage,
                term,
                defaultFilters,
            )
        ).then(() => {
        }).catch(() => {
        }).finally(() => {
            setLoading(false)
        })
    };

    useEffect(() => {
        const openArticlesSidebar = getGlobalState('openArticlesSidebar');

        if(openArticlesSidebar && tableData && !loading) {
            if(tableData.length) dispatch(setShowArticleSidebar(true))
            else dispatch(setShowNewArticleModal(true))

            removeQueryParam('openArticlesSidebar')
        }
    }, [tableData])

    // useEffect(() => {
    //     if(typeof catchSearchStringUpdate === 'function') catchSearchStringUpdate(searchString)
    // }, searchString)

    return (
        <>
            {open && tableData && (
                <div className={`articles-sidebar__wrapper ${absoluteSidebar ? 'absolute-sidebar': ''}`}>
                    {absoluteSidebar && <AppOverlay open={open} close={close} /> }

                    <div className="articles-sidebar">
                        <ArticlesSidebarHeader close={close} searchedTerm={searchString} searchHandler={searchHandler} />
                        <div className="articles-sidebar-content">
                           
                            {(!tableData.length && searchString.length && !loading) ?
                                 <p className="info-primary-bold no-results">No articles found for your search</p>
                            :
                            <>
                            {(tableData.length &&
                                tableData.map((article) => (
                                    <ArticlesSidebarItem
                                        key={article.id}
                                        topic={article.topic}
                                        addTraffic={article.estimatedVisits}
                                        // title={extractHeadingContent(article.content)}
                                        title={article.title}
                                        date={shortFormatDate(article.created)}
                                        author={article.writerName}
                                        score={article.score}
                                        articleId={article.id}
                                        outlineScore={article.outlineScore}
                                        articleScore={article.articleScore}
                                        showArticleScore={!!article.articleScore}
                                    />
                                ))) || <ArticlesSidebarItemPlaceholder />}
                                </>
                                }
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ArticlesSidebar