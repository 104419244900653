import React, {useEffect, useRef, useState} from 'react';
import ScoreCard from '../ScoreCard/ScoreCard'
import ArticleKeywordsTable from '../ArticleKeywordsTable/ArticleKeywordsTable'
import './writerLeftsidebar.css'
import {useDispatch, useSelector} from "react-redux";
import {useApplicationType} from "../../utils/useApplicationType";
import {useParams} from "react-router-dom";
import {loadingStep2Data} from "../../reducers/searchTopic";
import {getTopicCoverageData, getTopicCoverageLoaded, setTopicCoverage} from "../../reducers/topicCoverage";
import {getActiveSidebarTab, getEditorInstance, resetEditorData, setActiveSidebarTab} from "../../reducers/textEditor";
import {DEFAULT_ARTICLE_ID, HOME_PATH, TYPE_DESCRIPTION, TYPE_NATIVE} from "../../common/consts";
import Popup from "reactjs-popup";
import Button from "../../Shared/Button";
import { getApplicationMode } from "../../reducers/applicationMode";
import { getCurrentArticleId} from "../../reducers/articles";
// import useArticleId from "../../common/hooks/useArticleId";
// import {activeUser} from "../../reducers/user";
// import Chart from "../Chart";
// import RadarChartPlaceholder from "../RadarCharPlaceholder";
import Scrollbars from "react-custom-scrollbars";
import ArticleSwitch from './ArtricleSwitch'
import { useHistory, useLocation } from 'react-router-dom';
import { getGlobalState } from '../../globals';
import {PlusIcon, FileIcon, CogIcon, SpinnerIcon} from "../../UI_utils/SVGIcons";
import {dashboardTotalRows} from "../../reducers/dashboard";
import {getUserCanEdit, getUserData} from "../../reducers/user";
import {contentArticleData, getArticleEditorLoadingState  } from '../../reducers/articles'
import {WhiteTooltip} from "../UITooltips/UITooltip";
import {interfaceApiRequest} from "../../utils/dataToInterface";
import {getTitleCoverage, setTopicTitleCoverage} from "../../reducers/titleOptimization";

const LeftSidebar = ({writerMode, setWriterMode, updateShowCompetition, showAddArticleModal, showArticlesSidebar, openDefaultPrompt}) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const applicationType = useApplicationType();
    const urlParams = useParams();
    const hasSharedToken = getGlobalState('sharedToken');

    const userCanEdit = useSelector(getUserCanEdit);
    const appMode = useSelector(getApplicationMode);
    const isTopicLoading = useSelector(loadingStep2Data);
    const totalRows = useSelector(dashboardTotalRows);
    const topicCoverageLoaded = useSelector(getTopicCoverageLoaded);
    const keywords = useSelector(getTopicCoverageData);
    const editorInstance = useSelector(getEditorInstance);
    const articleData = useSelector(contentArticleData);
    const articleEditorLoading = useSelector(getArticleEditorLoadingState);
    const processedKeywords = useSelector(getTopicCoverageData);
    const titleCoverage = useSelector(getTitleCoverage);

    const [trackMode, setTrackMode] = useState(false);
    const [trackNewKeywords, setTrackNewKeywords] = useState([]);
    const [trackingNewKeywordsLoader, setTrackingNewKeywordsLoader] = useState(false);
    const [openPopup, setOpenPopup] = useState(false);
    const [localLoading, setLocalLoading] = useState(true);
    const [compactScoreCard, setCompactScoreCard] = useState(false);
    const [prevScrollTop, setPrevScrollTop] = useState(0);
    const scoreCardRef = useRef(null);
    const [listOptions, setListOptions] = useState([
        {
            label: 'Outline',
            key: 'outline',
            disabled: false,
            tooltipText: null
        },
        {
            label: 'Article',
            key:'article',
            disabled: true,
            tooltipText:`You haven't generated an article yet`

        },
       
    ])

    const currentArticleId = useSelector(getCurrentArticleId);
    const userData = useSelector(getUserData);
    const siteId = getGlobalState('site_id');
    const search = useLocation().search;
    const siteIdParams = new URLSearchParams(search).get('site_id');

    useEffect(() => {
        if(!!articleData || articleEditorLoading){
            let initialList = listOptions;
            initialList.forEach((list) => {
                if(list.key === 'article'){
                    list.disabled = false;
                }
            })
            setListOptions(initialList)
        }else{
            let initialList = listOptions;
            initialList.forEach((list) => {
                if(list.key === 'article'){
                    list.disabled = true;
                }
            })
            setListOptions(initialList)
        }
    }, [articleData, articleEditorLoading])
    
    useEffect(() => {
        // addind a local state flag to avoid the popup to open every time the article changes
        if (!isTopicLoading) {
            setLocalLoading(false);
        }
    }, [isTopicLoading]);

    useEffect(() => {
        // only open the popup if the article id is not the default one
        if (
            applicationType !== TYPE_NATIVE &&
            urlParams.id !== DEFAULT_ARTICLE_ID
        ) {
            // check if both flags are false meaning content is loaded but the keywords are empty
            if (!localLoading && !isTopicLoading && topicCoverageLoaded && keywords?.length === 0) {
                setOpenPopup(true);
            }
        }
    }, [localLoading, isTopicLoading, urlParams, keywords]);


    useEffect(() => {
        if(trackNewKeywords.length) {
            const updatedTrackNewKeywords = [];

            [...processedKeywords, ...titleCoverage].forEach(kw => {
                const kwName = kw.keyword || kw.name;
                if(trackNewKeywords.includes(kwName)) {
                    updatedTrackNewKeywords.push(kwName)
                }
            })

            setTrackNewKeywords(updatedTrackNewKeywords)
        }
    }, [processedKeywords, titleCoverage])

    const showContent = !isTopicLoading;

    const onRedirect = () => {
        // redirect to the search topic with a refresh, clean all redux state
        if (editorInstance) {
            dispatch(resetEditorData(true));
        }
        window.location.replace(`${HOME_PATH}/${DEFAULT_ARTICLE_ID}`);
    };

    if (openPopup) {
        return (
            <Popup
                open={openPopup}
                onClose={() => setOpenPopup(false)}
                closeOnDocumentClick={false}
            >
                <div className="topic-result">
                    <div className="topic-result-data">
                        <div className="topic-numbers">
                            <div className="numbers topic-searches"></div>
                            <div className="numbers topic-visits"></div>
                        </div>

                        <div className="no-data-found">
                            There is not enough data in the database to be able to create a
                            content brief. Please try another topic.
                        </div>

                        <div className="topic-result-actions">
                            <Button type="button" fullSize onClick={onRedirect}>
                                Write another article
                            </Button>
                        </div>
                    </div>

                    <img
                        className="topic-result-img"
                        src="/images/_topic-result.png"
                        alt="Search"
                        width="314px"
                        height="390px"
                    />
                </div>
            </Popup>
        );
    }

    const displayRadarChart = applicationType !== TYPE_DESCRIPTION;
    // const listOptions = [
    //     {
    //         label: 'Outline',
    //         key: 'outline',
    //         disabled: false
    //     },
    //     {
    //         label: 'Article',
    //         key:'article',
    //         disabled: false
    //     },
       
    // ]

    const addArticle = () => showAddArticleModal();

    const showArticles = () => showArticlesSidebar();

    const showDefaultPrompt = () => openDefaultPrompt()


    const handleUpdate = (e, test) => {
        if(scoreCardRef.current) {
            const scoreCardH = scoreCardRef.current.getBoundingClientRect().height;
            const scrollingDown = e.scrollTop > prevScrollTop;
            const scoreBoxIsHidden = e.scrollTop > scoreCardH;
            const marginError = 80;
            const hideSmallBoxCondition = !scrollingDown && e.scrollTop < scoreCardH + marginError;

            if(scoreBoxIsHidden && scrollingDown) {
                setCompactScoreCard(true);
            } else if(hideSmallBoxCondition) {
                setCompactScoreCard(false);
            }

            setPrevScrollTop(e.scrollTop)
        }
    };

    const trackNewKeywordsHandler = () => {
        const alreadyTracked = [];

        [...processedKeywords, ...titleCoverage].forEach(kw => {
            if(kw.tracked && (kw.keyword || kw.name))
                alreadyTracked.push(kw.keyword || kw.name)
        })

        const allKw = [...trackNewKeywords, ...alreadyTracked]

        if(!trackingNewKeywordsLoader && userCanEdit) {
            setTrackingNewKeywordsLoader(true);

            interfaceApiRequest('checkTopicAndStartArticleMessage', {trackKeywordsFromWriter: allKw})
                .then(resp => {
                    if(resp.message) {
                        console.log('error => ', resp.message)
                    } else {
                        const processedKeywordsNewArray = processedKeywords.map(kw => {
                            const copyKw = {...kw}
                            if(trackNewKeywords.includes(copyKw.keyword))
                                copyKw.tracked = true
                            return copyKw
                        })
                        const titleCoverageNewArray = titleCoverage.map(kw => {
                            const copyKw = {...kw}
                            if(trackNewKeywords.includes(copyKw.keyword))
                                copyKw.tracked = true
                            return copyKw
                        })

                        dispatch(setTopicCoverage(processedKeywordsNewArray));
                        dispatch(setTopicTitleCoverage(titleCoverageNewArray));
                        setTrackNewKeywords([])
                        setTrackMode(false)
                    }
                })
                .catch(err => {
                    console.log('error => ', err)
                })
                .finally(() => {
                    setTrackingNewKeywordsLoader(false);
                })
        }
    }

    return (
        <div className="left-sidebar-container">
            <div className="left-sidebar-container-header">
                <div className="left-sidebar-heading">
                    <span className="left-sidebar-heading__title">Content Writer</span>
                    {!hasSharedToken && (
                        <WhiteTooltip placement="bottom-end" title={"Campaign brief"}>
                            <div className="action" onClick={showDefaultPrompt}>
                                <div className="actionIcon">
                                    <CogIcon/>
                                </div>
                            </div>
                        </WhiteTooltip>
                    )}
                </div>
                <div className="left-sidebar-actions">
                {!hasSharedToken && (
                    <>
                        <WhiteTooltip placement="bottom-end" title={"View past articles"}>
                            <div className="action" onClick={showArticles}>
                                <div className="actionText">{totalRows ? totalRows : ''}</div>
                                <div className="actionIcon">
                                    <FileIcon />
                                </div>
                            </div>
                        </WhiteTooltip>
                        <WhiteTooltip placement="bottom-end" title={"Create new article"}>
                            <div className="action" onClick={addArticle}>
                                <div className="actionIcon">
                                    <PlusIcon />
                                </div>
                            </div>
                        </WhiteTooltip>
                    </>
                )}
                </div>
            </div>
            <div className="left-sidebar-container-subheader">
                <ArticleSwitch selectedOption={writerMode} options={listOptions} onSwitch={setWriterMode} />
            </div>
            <Scrollbars
                onUpdate={handleUpdate}
            >
                <div className="left-sidebar-container-content">
                    <ScoreCard ref={scoreCardRef}
                        compact={compactScoreCard}
                        writerMode={writerMode}
                        updateShowCompetition={updateShowCompetition}
                    />
                    <ArticleKeywordsTable
                        appMode={appMode}
                        isTopicLoading={isTopicLoading}
                        showContent={showContent}
                        iswriterMode={displayRadarChart}
                        writerMode={writerMode}
                        trackMode={trackMode}
                        setTrackMode={(data) => setTrackMode(data)}
                        trackNewKeywords={trackNewKeywords}
                        setTrackNewKeywords={(data) => setTrackNewKeywords(data)}
                        trackingNewKeywordsLoader={trackingNewKeywordsLoader}
                    />
                </div>
            </Scrollbars>

            {trackMode && (
                <div className={`track-keywords__actions ${trackingNewKeywordsLoader ? 'loading' : ''}`}>
                    {!!trackNewKeywords.length && (
                        <div className="track-keyword" onClick={trackNewKeywordsHandler}>
                            Track {trackNewKeywords.length} selected keyword{trackNewKeywords.length === 1 ? '' : 's'}
                            {trackingNewKeywordsLoader && <SpinnerIcon />}
                        </div>
                    )}
                    <div className="cancel-track-keyword" onClick={() => setTrackMode(false)}>
                        Cancel
                    </div>
                </div>
            )}
        </div>
    );
}

export default LeftSidebar;